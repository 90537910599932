var body = document.body
var navigation = document.querySelectorAll('.pp-header__menu');
const desktop = window.matchMedia( "(min-width: 1024px)" );

//Hamburger Menu Button
document.addEventListener('DOMContentLoaded', function() {

  var toggleBtn = document.querySelector('.pp-header__menu-button')
  var hamburgerIcon = document.querySelector('.pp-header__menu-button__hamburger')

  if(toggleBtn) {
    toggleBtn.addEventListener('click', function() {
      body.classList.toggle('pp-header__nav--active')
      hamburgerIcon.classList.toggle('pp-header__menu-button__hamburger--active')
    })
  }
})

//Accessibility and Menu Functionality 
Array.prototype.slice.call(navigation).forEach(function (menuElements) {

  var allowMultiple = menuElements.hasAttribute('data-allow-multiple');
  var allowToggle = true

  menuElements.addEventListener('click', function (event) {
    var target = event.target;

    if (target.nodeName === 'A') {
      target = target.parentElement
    }

    if(target.classList.contains('pp-header__menu-item--has-children') && target.children[0].href == '#'){
      event.preventDefault();
    }

    if (target.classList.contains('pp-header__menu-item--has-children')) {

      var isExpanded = target.children[0].getAttribute('aria-expanded') === 'true';
      var active = menuElements.querySelector('[aria-expanded="true"]');

      if (!allowMultiple && active && active !== target.children[0]) {
        active.setAttribute('aria-expanded', 'false');
        target.classList.remove('active');

        if (!allowToggle) {
          active.removeAttribute('aria-disabled');
        } 
      }

      if (!isExpanded) {
        target.children[0].setAttribute('aria-expanded', 'true');
        target.classList.add('active');

        if (!allowToggle) {
          target.children[0].setAttribute('aria-disabled', 'true');
        }

      } else if (allowToggle && isExpanded) {
        target.children[0].setAttribute('aria-expanded', 'false');
        target.classList.remove('active');
      }
    }
  });
});
